import React, { useState } from 'react';
import {
  Button,
  Modal,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ThemeProvider,
  createTheme,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Chip,
  Stack
} from '@mui/material';

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          maxWidth: 400,
          margin: '0 0 0 0',
          borderRadius: 16, // Adjust the value to control the roundness of corners
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          height: 200, // Set the height of the image as needed
          borderRadius: '16px', // Match the card's borderRadius for the top corners
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: '95vw',
          maxHeight: '90vh',
          backgroundColor: '#F7E7CE'
        },
      },
    },
  },
});

interface Guest {
  name: string;
  email?: string;
  foodPreference: string;
}

interface AddGuestModalProps {
  onAddGuest: (guest: { name: string; email?: string; foodPreference: string }) => void;
  deleteGuest: (index:number) => void;
  guests: Guest[];
}

const AddGuestModal: React.FC<AddGuestModalProps> = ({ onAddGuest, guests, deleteGuest }) => {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [foodPreference, setFoodPreference] = useState('');

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleAddGuest = () => {
    // Validate input if needed
    if (name.trim() === '' || foodPreference === '') {
      // Handle validation error
      return;
    }

    // Call the onAddGuest callback with guest information
    onAddGuest({
      name,
      email: email.trim(), // Optional email
      foodPreference,
    });

    // Reset state and close the modal
    setName('');
    setEmail('');
    setFoodPreference('');
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="pb-5 pt-5">
        <Button variant="contained" sx={{ backgroundColor: '#53AB83' }} size="large" onClick={handleOpen}>Add Guest</Button>
        <div className="pt-8 flex flex-wrap justify-center items-center space-x-2">

          {guests.map((guest, index) => {
            console.log(guest);
            return <Chip
              className="font-bold"
              sx = {{ backgroundColor: '#53AB83'}}
              label={guest.name}
              onDelete={() => deleteGuest(index)}
            />
          })}
        </div>
      </div>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Add Guest</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel id="foodPreference-label">Food Preference</InputLabel>
            <Select
              labelId="foodPreference-label"
              value={foodPreference}
              onChange={(e) => setFoodPreference(e.target.value as string)}
              label="Food Preference"
            >
              <MenuItem value="chicken">Chicken</MenuItem>
              <MenuItem value="salmon">Salmon</MenuItem>
              <MenuItem value="vegan">Vegan</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddGuest} color="primary">
            Add Guest
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddGuestModal;


import React from 'react';

const Accomodations: React.FC = () => {
  // Import the JSON data
  const FAQData: AccomodationsProps[] = require('./accomodations.json');
  const OtherData: AccomodationsProps[] = require('./accomodationsOther.json');

  return (
    <div className="container mx-auto mt-8 travel-container">
      <h2 className="text-6xl font-bold text-center title mb-4 mainColor">Accomodation Info</h2>

      <div className="space-y-6">
        {FAQData.map((step, index) => {
          return <FAQStep key={index} {...step} />
        }
        )}
      </div>
      
      <h2 className="text-6xl font-bold text-center title mb-4 mainColor">Other Options</h2>
      <div className="space-y-6">
        {OtherData.map((step, index) => {
          return <FAQStep key={index} {...step} />
        }
        )}
      </div>
    
    </div>
  );
};
interface AccomodationsProps {
  question: string;
  answer: string;
}

const FAQStep: React.FC<AccomodationsProps> = ({ name, link, description, img, additional }) => {
  return (
    <div className="p-6 rounded-md travel-step">
      <div className="flex">
        <div className="ml-4">
          <div className="text-3xl font-medium mb-4 text-center">{name}</div>
          <div><img className="max-h-72 block m-auto p-2" src={img} /></div>
          <div className="text-black font-bold text-center">To book please visit <a className='text-blue-600' href={link}>{name} website</a></div>
          <div className="text-black text-center">
            <p className="pb-2">{description}</p>
            <p>{additional}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accomodations;



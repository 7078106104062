// Navbar.tsx
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Link as RouterLink } from '@mui/material';
import { Link as RouteLink } from 'react-router-dom';

const Nav: React.FC = () => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#FEF1DD',  fontWeight: 'bold' }}>
      <Toolbar>
        <Typography className="title" variant="h6" component={RouteLink} to="/" sx={{ flexGrow: 1, color: '#53AB83',  fontWeight: 'bold' }}>
                      <span>Yuri</span>
            <img src="https://d3t14gfu9ehll4.cloudfront.net/themes/0233e832-8614-41eb-b276-a6d55c1f847c/root_SmallGraphic.png" className="h-10 inline"/>
            <span>Stephen</span>

        </Typography>
        <Button className="title" color="inherit" sx={{ color: '#53AB83', fontWeight: 'bold' }} component={RouteLink} to="/rsvp">
          RSVP
        </Button>
        <Button className="title" color="inherit" sx={{ color: '#53AB83', fontWeight: 'bold' }} component={RouteLink} to="/travel">
          Info
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;


// RsvpForm.tsx
import React from 'react';
import { useForm, SubmitHandler,getValues, useFormState, Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Checkbox,
  Button,
  Container,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
} from '@mui/material';
import AddGuestModal from '../components/AddGuestModal';

interface Guest {
  name: string;
  email?: string;
  foodPreference: string;
}

interface RsvpFormData {
  name: string;
  rsvp: boolean;
  guests: Guest[];
  email: string;
  bus: boolean;
  songs: string;
  meal: string;
  allergies: string;
  misc: string;
  currentStep: number;
  id: string;
}

interface RsvpFormProps {
  onSubmit: SubmitHandler<RsvpFormData>;
}

const RsvpForm: React.FC<RsvpFormProps> = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch,
  } = useForm<RsvpFormData>({
    defaultValues: {
      currentStep: 1,
      id: new Date().getTime()+"",
      bus: false,
      songs: "",
      misc: "",
      rsvp: true,
      guests: []
    },
  });

  function addGuest(g:Guest){
    console.log(g.name);
    let currentGuests = getValues("guests");
    let newGuests = [...currentGuests, g];
    console.log(newGuests);
    setValue("guests", newGuests);
  }

  function removeGuest(index:number){
    let currentGuests = getValues("guests");
    currentGuests.splice(index, 1);
    setValue("guests", currentGuests);
  }

  const currentStep = watch('currentStep');
  const allGuests = watch('guests');
  const isRsvp = watch("rsvp", true)

  const handleNextStep = () => setValue('currentStep', currentStep + 1);
  const handlePrevStep = () => setValue('currentStep', currentStep - 1);

  const handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(field, e.target.value);
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    ) => {
      console.log(getValues('rsvp'));
      setValue('rsvp', event.target.value == 'true');
      console.log(getValues('rsvp'));
    };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <Typography className="title mainColor" variant="h3" gutterBottom>
          RSVP
        </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          {currentStep === 1 && (
            <>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Full Name"
                      fullWidth
                      required
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : ''}
                      onChange={handleInputChange('name')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: 'Email is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="email"
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                      onChange={handleInputChange('email')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={24}>
                <Controller
                  name="rsvp"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      value={getValues('rsvp')+""}
                      aria-label="Platform"
                      onChange={handleToggleChange}
                    >
                      <ToggleButton size="large" name="true" value="true">
                        Accept
                      </ToggleButton>
                      <ToggleButton size="large" name="false" value="false">
                        Decline
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Grid>
            </>
          )}

          {currentStep === 2 && isRsvp && (
            <>
              <Grid item xs={12}>
                <Controller
                  name="meal"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">Meal Preference</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleInputChange('meal')}
                      >
                        <FormControlLabel value="Salmon" control={<Radio />} label="Salmon" />
                        <FormControlLabel value="Chicken" control={<Radio />} label="Chicken" />
                        <FormControlLabel value="Vegan" control={<Radio />} label="Vegan" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="allergies"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Allergies" fullWidth onChange={handleInputChange('allergies')} />
                  )}
                />
              </Grid>
            </>
          )}

          {currentStep === 3 && isRsvp && (
            <>
              <Grid item xs={12}>
                <AddGuestModal onAddGuest={addGuest}guests={allGuests} deleteGuest={removeGuest}/>
              </Grid>
            </>
          )}
        </Grid>

        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          {(isRsvp && currentStep > 1) && (
            <Button variant="outlined" onClick={handlePrevStep}>
              Previous
            </Button>
          )}

          {(isRsvp && currentStep < 3) && (
            <Button style={{backgroundColor: "#53AB83", marginLeft: "1rem"}} variant="contained" onClick={handleNextStep}>
              Next
            </Button>
          )}

          {(!isRsvp || currentStep === 3) && (
            <Button type="submit" variant="contained" color="success" style={{ marginLeft: '1rem' }}>
              Submit RSVP
            </Button>
          )}
        </div>
      </form>
    </Container>
  );
};

export default RsvpForm;


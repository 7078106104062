import React from 'react';

const TravelSteps: React.FC = () => {
  // Import the JSON data
  const travelStepsData: TravelStepProps[] = require('./travelSteps.json');

  return (
    <div className="container mx-auto mt-8 travel-container">
      <h2 className="text-6xl font-bold text-center title mb-4 mainColor">How to get to Islay</h2>

      <div className="space-y-6">
        {travelStepsData.map((step, index) => {
          console.log(step);
          return <TravelStep key={index} {...step} />
        }
        )}
      </div>
    </div>
  );
};
interface TravelStepProps {
  stepNumber: number;
  title: string;
  description: string;
  imageSrc: string;
}

const TravelStep: React.FC<TravelStepProps> = ({ stepNumber, title, description, imageSrc }) => {
  return (
    <div className="p-6 rounded-md travel-step">
      <div className="flex items-center">
        {/* Add the square image to the left side */}
        <div className="ml-4">
          <div className="text-2xl font-semibold mb-2">Step {stepNumber}</div>
          <div className="text-lg font-medium mb-4">{title}</div>
          <div className="pb-2">
          <img src={imageSrc} className="object-cover rounded-md" />
          </div>
          <div className="text-gray-600" dangerouslySetInnerHTML={{__html: description}} />
        </div>
      </div>
    </div>
  );
};

export default TravelSteps;

/** @jsxImportSource @emotion/react */
// TravelPage.tsx
import React from 'react';
import { Button, Container, Typography, Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import { Link as RouteLink } from 'react-router-dom';
import Nav from "../components/nav";
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import backgroundVideo from '../assets/videos/background.mp4';
import flowers from '../assets/images/flowers.png';
import { useEffect, useState } from "react";
import TravelCard from "./TravelCard";
import TravelSteps from "./TravelSteps";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FAQInfo from "./FAQInfo";
import Accomodations from './accomodations';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'inherit',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const TravelPage = () => {

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  return (
    <div
      css={css`
          min-height: 100vh;
          overflow: hidden;
          `}
    >
      <Nav />
      <div
        css={css`
					display: flex;
					min-height: calc(100vh - 50px);
				`}
      >
        <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 10 }} id="test">
          <div>
            <Container
              style={{
                zIndex: 1,
                color: '#53AB83',
              }}
            >
              <Typography className="title text-center mainColor" variant="h2" gutterBottom>
                Information
              </Typography>
              <div className="flex flex-wrap justify-center items-center">
                <TravelCard
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4"
                  image="https://st4.depositphotos.com/4799321/27531/v/600/depositphotos_275319238-stock-illustration-airplane-flight-route-icon-in.jpg"
                  title="Getting to Islay"
                  description="Everything to know in order to successfully get to the wedding!"
                  content=<TravelSteps />
                />
                <TravelCard
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4"
                  image="https://st3.depositphotos.com/4799321/37617/v/600/depositphotos_376172974-stock-illustration-question-answer-icon-comic-style.jpg"
                  title="FAQ"
                  description="Frequently asked questions about Islay or Scotland"
                  content=<FAQInfo />
                />
                <TravelCard
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4"
                  image="https://st2.depositphotos.com/4799321/44550/v/600/depositphotos_445500412-stock-illustration-hotel-stars-sign-icon-comic.jpg"
                  title="Accomodations"
                  description="Everything to know about where to stay while you are visiting Islay"
                  content=<Accomodations />
                />
              </div>
            </Container>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default TravelPage;



 /** @jsxImportSource @emotion/react */
// LandingPage.tsx
import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { Link as RouteLink } from 'react-router-dom';
import Nav from "../components/nav";
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import backgroundVideo from '../assets/videos/background.mp4';
import flowers from '../assets/images/flowers.png';
import {useEffect, useState} from "react";



const LandingPage = () => {

  const [width, setWidth] = useState<number>(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

  function displayVideo(){
    if(!isMobile){
      return (<video muted autoPlay loop id="video">
              <source src={backgroundVideo} type="video/mp4"/>
          </video>);
    }else{
      return <div id="mobile">
      </div>
    }
  }

  return (
    <div
			css={css`
          min-height: 100vh;
          overflow: hidden;
          `}
		>
      <Nav />
			<div
				css={css`
					display: flex;
					min-height: calc(100vh - 50px);
				`}
			>
      <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 10 }} id="test">
        <div>
        {displayVideo()}
      <Container
        style={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: !isMobile ? '#fff' : '#53AB83',
        }}
      >
        <Typography className="title" variant="h3" gutterBottom>
          The Wedding of
        </Typography>
        <Typography className="title" variant="h2" paragraph>
          Yuri & Stephen
        </Typography>
        <Button style={{
        borderRadius: 20,
        backgroundColor: "#53AB83",
        padding: "18px 36px",
        fontSize: "18px"
        }}
    variant="contained" size="large" color="primary" component={RouteLink} to="/rsvp">
          RSVP
        </Button>
      </Container>
          </div>
      </Box>
    </div>
    </div>
  );
};

export default LandingPage;


import React from 'react';
import Confetti from 'react-confetti'
import { Button, Container, Typography } from '@mui/material';

const SuccessForm: React.FC = () => {

  return (
    <>
    <Confetti/>
      <Container
        style={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: '#53AB83',
        }}
      >
        <Typography variant="h1" gutterBottom>
          Thanks!
        </Typography>
    </Container>
    </>
  )


};

export default SuccessForm;

import LandingPage from "./pages/landing";
import RsvpPage from "./pages/RsvpPage";
import TravelPage from "./pages/TravelPage";
import GuestPage from './pages/GuestPage';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/rsvp" element={<RsvpPage />} />
        <Route path="/travel" element={<TravelPage />} />
        <Route path="/guests" element={<GuestPage />} />
      </Routes>
    </Router>
  );
}

export default App;

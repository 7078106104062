/** @jsxImportSource @emotion/react */
// GuestPage.tsx
import React from 'react';
import { Button, Container, Typography, Grid, Popover } from '@mui/material';
import Nav from "../components/nav";
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'inherit',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const GuestPage = () => {

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;


  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://osdueh8xo5.execute-api.us-east-1.amazonaws.com/rsvp');
        setRows(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'rsvp', headerName: 'RSVP', width: 150 },
    { field: 'meal', headerName: 'Meal Preference', width: 200 },
    {
      field: 'guests', headerName: 'Guests', width: 300,
      valueGetter: (params) => {
        let value = params.row.guests;
        if (value.constructor === Array) {
          console.log('its an array');
          let names = "";
          value.forEach((guest) => names = names + guest.name);
          return names;
        } else {
          return value;
        }
      }
    },
    { field: 'allergies', headerName: 'Allergies', width: 200 },
    { field: 'bus', headerName: 'Bus', width: 150 },
    { field: 'misc', headerName: 'Miscellaneous', width: 200 },
    { field: 'songs', headerName: 'Songs', width: 200 },];


  return (
    <div
      css={css`
          min-height: 100vh;
          overflow: hidden;
          `}
    >
      <Nav />
      <div
        css={css`
					display: flex;
					min-height: calc(100vh - 50px);
				`}
      >
        <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 10 }} id="test">
          <div>
            <Container
              style={{
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: '#53AB83',
              }}
            >
              <Typography className="title mainColor" variant="h2" gutterBottom>
                RSVP Guests
              </Typography>
              <div style={{ width: '90%' }}>
                <DataGrid rows={rows} columns={columns} />
              </div>
            </Container>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default GuestPage;




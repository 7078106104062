/** @jsxImportSource @emotion/react */
// LandingPage.tsx
import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { Link as RouteLink } from 'react-router-dom';
import Nav from "../components/nav";
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import RsvpForm from './RsvpForm';
import SuccessForm from './SuccessForm';
import axios from 'axios';
import { useForm, SubmitHandler, getValues, useFormState, Controller } from 'react-hook-form';
import { useState } from 'react';
import Alert from '@mui/material/Alert';

const axiosInstance = axios.create({
  timeout: 2000,
  headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});

const RsvpPage: React.FC = () => {

  const [isSubmitted, setIsSubmited] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFormSubmit = (data: any) => {
    // Assuming you will handle form submission logic here

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: 'https://osdueh8xo5.execute-api.us-east-1.amazonaws.com/rsvp',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsSubmited(true);
        setIsError(false);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      });

  };

  function Display({ isSubmitted, isError }) {
    if (!isSubmitted && !isError) {
      return <RsvpForm onSubmit={handleFormSubmit} />
    } else if (isSubmitted) {
      return <SuccessForm />
    } else {
      return <h1> Something went wrong, please try again later </h1>
    }
  }

  return (
    <div css={css`
          min-height: 100vh;
          `}
    >
      <Nav />
      <div
        css={css`
					display: flex;
					min-height: calc(100vh);
				`}
      >
        <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 10 }}>

          <Container
            style={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              background: '#F7E7CE',
              minHeight: '90%',
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: 'black',
              borderRadius: '30px',
              overflow: 'hidden'
            }}

          >
            <Display isSubmitted={isSubmitted} isError={isError} />
          </Container>
        </Box>
      </div>
    </div>
  );
};

export default RsvpPage;


import React from 'react';

const FAQInfo: React.FC = () => {
  // Import the JSON data
  const FAQData: FAQInfoProps[] = require('./FAQData.json');

  return (
    <div className="container mx-auto mt-8 travel-container">
      <h2 className="text-6xl font-bold text-center title mb-4 mainColor">FAQ's</h2>

      <div className="space-y-6">
        {FAQData.map((step, index) => {
          return <FAQStep key={index} {...step} />
        }
        )}
      </div>
    </div>
  );
};
interface FAQInfoProps {
  question: string;
  answer: string;
}

const FAQStep: React.FC<FAQInfoProps> = ({ question, answer }) => {
  return (
    <div className="p-6 rounded-md travel-step">
      <div className="flex items-center">
        {/* Add the square image to the left side */}
        <div className="ml-4">
          <div className="text-lg font-medium mb-4">{question}</div>
          <div className="text-gray-600">{answer}</div>
        </div>
      </div>
    </div>
  );
};

export default FAQInfo;


import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  createTheme
} from '@mui/material';


interface TravelCardProps {
  image: string;
  title: string;
  description: string;
  content: element;

};

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          maxWidth: 400,
          margin: '0 0 0 0',
          borderRadius: 16, // Adjust the value to control the roundness of corners
          margin: 10,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          height: 200, // Set the height of the image as needed
          borderRadius: '16px', // Match the card's borderRadius for the top corners
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: '95vw',
          maxHeight: '90vh',
          backgroundColor: '#F7E7CE'
        },
      },
    },
  },
});

const TravelCard: React.FC<TravelCardProps> = ({ image, title, description, content }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
  <ThemeProvider theme={theme}>
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          sx={{ maxHeight: 250, objectFit: "fill"  }}
          component="img"
          alt="Card Image"
          image={image}
        />
        <CardContent>
          <Typography className="title" variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          <Button style={{backgroundColor: "#53AB83"}} variant="contained" color="primary" onClick={handleOpenModal}>
            Learn More
          </Button>
        </CardContent>
      </Card>

      <Dialog open={openModal} onClose={handleCloseModal}>
        {content}
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
  );
};

export default TravelCard;

